<template>
  <span @click="openFileBrowser">
    <slot name="btn" :loading="loading" ></slot>
    <input type="file" :multiple="multiple" ref="input" @change="changeEvent" />
  </span>
</template>

<script>
import { Uploader } from "@/services/active-storage.api.js";

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    autoOpen: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    changeEvent() {
      console.log("CHANGE EVENT", this.input.value);
      Array.from(this.input.files).forEach((file) => {
        this.upload(file);
        this.$emit("startedUpload", file);
      });
      this.input.value = null;
    },
    blurEvent() {
      console.log("suspect input cancel");
      this.$emit("cancelSelection");
    },
    directUploadWillStoreFileWithXHR(request) {
      request.upload.addEventListener("progress", (event) =>
        this.directUploadDidProgress(event)
      );
    },

    directUploadDidProgress(event) {
      this.$emit("uploadProgress", event);
    },
    async upload(file) {
      const uploader = new Uploader(file);
      uploader.directUploadWillStoreFileWithXHR =
        this.directUploadWillStoreFileWithXHR;
      this.progress = 0;
      try {
        const blob = await uploader.create();
        this.$emit("upload", { file, blob });
      } catch (error) {
        this.sendError(error);
      }
    },
    openFileBrowser() {
      this.input.click();
    },
  },
  computed: {
    input() {
      return this.$refs.input;
    },
  },
  mounted() {
    if (this.autoOpen) this.input.click();
    this.input.focus();
  },
};
</script>

<style lang='scss' scoped>
input[type="file"] {
  display: none;
}
</style>
