<template>
  <v-container fluid class="px-0 py-8">
    <v-card color="transparent" flat max-width="500">
      <v-row dense no-gutters wrap>
        <v-col cols="4"></v-col>
        <v-col cols="2"></v-col>
        <v-col cols="2" align="center"
          ><span class="subtitle-2 font-weight-bold">{{
            $t("userProfile.notificationsSettings.app")
          }}</span></v-col
        >
        <v-col cols="2" align="center"
          ><span class="subtitle-2 font-weight-bold">{{
            $t("userProfile.notificationsSettings.email")
          }}</span></v-col
        >
        <v-col cols="2" align="center"
          ><span class="subtitle-2 font-weight-bold">{{
            $t("userProfile.notificationsSettings.microsoftTeams")
          }}</span></v-col
        >
      </v-row>

      <template v-for="(group, groupIndex) in notificationSettings">
        <v-container fluid class="px-0 py-2" :key="group.groupName">
          <v-row
            dense
            no-gutters
            wrap
            :class="groupIndex > 0 ? 'pt-8' : 'pt-0'"
          >
            <v-col cols="4" align="end"
              ><span class="subtitle-2 font-weight-bold">{{
                group.groupName
              }}</span></v-col
            >
          </v-row>
          <v-row
            no-gutters
            wrap
            v-for="field in group.fields"
            :key="field.name"
          >
            <v-col cols="4" align="end"
              ><span class="caption"> {{ field.name }}</span></v-col
            >
            <v-col cols="2"></v-col>
            <v-col
              cols="2"
              align="center"
              justify="center"
              class="text-xs-center"
            >
              <v-simple-checkbox
                v-model="field.values[0]"
                color="button_blue"
                hide-details
                class="my-0 align-center justify-center"
              ></v-simple-checkbox>
            </v-col>
            <v-col cols="2" align="center"
              ><v-simple-checkbox
                v-model="field.values[1]"
                color="button_blue"
                hide-details
                class="my-0 align-center justify-center"
              ></v-simple-checkbox
            ></v-col>
            <v-col cols="2" align="center"
              ><v-simple-checkbox
                v-model="field.values[2]"
                color="button_blue"
                hide-details
                class="my-0 align-center justify-center"
              ></v-simple-checkbox
            ></v-col>
          </v-row>
        </v-container>
      </template>

      <v-divider class="mt-5 ml-8" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="success" @click="">
          {{ $t("userProfile.saveChanges") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "settingsNotifications",
  components: {},
  data() {
    return {
      notificationSettings: [
        {
          groupName: this.$t("userProfile.notificationsSettings.meetings"),
          fields: [
            {
              name: this.$t("userProfile.notificationsSettings.newMeeting"),
              values: [true, false, true],
            },
            {
              name: this.$t("userProfile.notificationsSettings.agendaUpdates"),
              values: [true, true, true],
            },
            {
              name: this.$t("userProfile.notificationsSettings.meetingMinutes"),
              values: [false, true, false],
            },
          ],
        },
        {
          groupName: this.$t("userProfile.notificationsSettings.tasks"),
          fields: [
            {
              name: this.$t("userProfile.notificationsSettings.assigned"),
              values: [true, true, false],
            },
            {
              name: this.$t("userProfile.notificationsSettings.dueDate"),
              values: [true, true, false],
            },
            {
              name: this.$t("userProfile.notificationsSettings.done"),
              values: [true, true, true],
            },
          ],
        },
      ],
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
