<template>
  <v-container fluid class="px-0 py-8">
    <v-card color="transparent" flat max-width="500" class="ml-2">
      <!-- Outlook Extension -->
      <v-card class="mx-auto" max-width="430">
        <v-list two-line class="pt-2">
          <v-list-item>
            <v-list-item-avatar tile>
              <img width="26"  height="auto" alt="GetCollective" src="/images/logo_dark.png" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-bold pl-2">{{
                $t("integration.outlookExtension")
              }}</v-list-item-title>

              <v-list-item-subtitle>
                <v-chip color="light_grey" small
                  >Version: <b>1.0.0.0</b></v-chip
                >

                <v-chip color="light_grey" small
                  >Last updated: <b>11/6/2020</b></v-chip
                ></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card-text class="pt-0">
          <div>
            <p><b>Add a GetCollective meeting to any Outlook event.</b></p>
            <p>
              This Add-in allows you to easily add a GetCollective meeting to
              any new or existing event on your calendar.
            </p>
            <p>
              Just click on the GetCollective icon and the GetCollective meeting
              details are automatically added to the event description of the
              calendar.
            </p>
          </div>

          <v-img
            height="164"
            cover
            class="mb-4"
            src="/images/integrations/outlookExtensionPreview.png"
          ></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :href="addInUrl"
            target="_blank"
            color="button_blue"
            class="white--text"
            depressed
          >
            {{ $t("integration.getItNow") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import links from "@/mixins/links";

export default {
  name: "settingsIntegration",
  mixins: [links],
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
