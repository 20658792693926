<template>
  <div style="background-color: white">
    <headerToolbar color="white" divider>
      <template v-slot:toolbarTitle>
        <b class="ml-3">{{ $t("navigationMenu.settings") }}</b>
      </template>
    </headerToolbar>

    <v-container fluid class="px-6 pt-0">
      <v-tabs
        background-color="transparent"
        color="button_blue"
        v-model="selectedTab"
        left
      >
        <v-tab href="#profile">{{ $t("userProfile.profile") }}</v-tab>
        <!-- <v-tab href="#notifications">{{$t("userProfile.notifications")}}</v-tab>-->
        <v-tab href="#integration">{{ $t("userProfile.integration") }}</v-tab>
        <!-- <v-tab href="#securityPrivacy">{{$t("userProfile.securityPrivacy")}}</v-tab> -->
      </v-tabs>
    </v-container>
    <v-tabs-items
      v-model="selectedTab"
      style="height: calc(100vh - 108px); overflow-y: auto"
    >
      <v-tab-item value="profile">
        <settingsProfile />
      </v-tab-item>

      <!-- <v-tab-item value="notifications">
       <settingsNotifications />
      </v-tab-item> -->

      <v-tab-item value="integration">
        <settings-integrations />
      </v-tab-item>

      <!--  <v-tab-item value="securityPrivacy">
        <updatePwd />
      </v-tab-item> -->
    </v-tabs-items>
  </div>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import settingsProfile from "./settingsProfile";
import settingsNotifications from "./settingsNotifications";
//import updatePwd from "@/components/users/updatePwd";
import SettingsIntegrations from "./settingsIntegrations.vue";

export default {
  name: "Settings",
  mixins: [],
  components: {
    headerToolbar,
    settingsProfile,
    settingsNotifications,
    //updatePwd,
    SettingsIntegrations,
  },
  data() {
    return {
      selectedTab: "profile",
    };
  },
  methods: {},
  computed: {},
  mounted() {},
  beforeDestroy() {},
  created() {
    document.title = this.$t("navigationMenu.settings");
    this.$amplitude.getInstance().logEvent("Page: Settings");
  },
};
</script>

<style>
.theme--light.v-application {
  background-color: white !important;
}
</style>

