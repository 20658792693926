export default {
  computed: {
    addInUrl() {
      if (this.$i18n.locale === "fr")
        return "https://info.getcollective.com/installer-le-complement-pour-outlook";
      else
        return "https://info.getcollective.com/installing-the-add-in-for-outlook";
    },
  }
};
