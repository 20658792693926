<template>
  <v-container fluid class="px-10 pt-0">
    <v-card color="transparent" class="pa-4 px-0"  flat max-width="500">
      <v-subheader class="pl-0">
        {{ $t("userProfile.profilePicture") }}
      </v-subheader>


      <v-row no-gutters class="pb-5" align="center">
        <userAvatar
          v-if="currentUser"
          :size="72"
          :last_name="currentUser.last_name"
          :first_name="currentUser.first_name"
          :avatar_url="currentUser.avatar_url"
          :email="currentUser.email"
        />

        <v-col class="ml-5">
          <uploader v-on:upload="setUserAvatarBlob($event.file, $event.blob)">
            <template v-slot:btn>
              <v-btn
                class="mr-3 white--text"
                depressed
                small
                color="button_blue"
              >
                {{ $t("userProfile.change") }}
              </v-btn>
            </template>
          </uploader>
          <v-btn
            outlined
            depressed
            small
            @click="removeAvatar()"
            :disabled="noProfilePicture"
          >
            {{ $t("userProfile.remove") }}
          </v-btn>
          <p class="mb-0">
            <span class="caption">{{ $t("userProfile.uploadRequierements") }}</span>
          </p>
        </v-col>
      </v-row>

      <v-text-field
        id="first_name"
        v-model="user.first_name"
        :label="$t('userProfile.firstName')"
        filled
        dense
        autocomplete="off"
      />

      <v-text-field
        v-model="user.last_name"
        :label="$t('userProfile.lastName')"
        filled
        dense
        autocomplete="off"
      />

       <v-text-field
        v-model="user.job"
        :label="$t('userProfile.jobTitle')"
        filled
        dense
        autocomplete="off"
      />

      <v-subheader class="pl-0">
        {{ $t("userProfile.languages") }}
      </v-subheader>

      <v-layout column>
        <v-flex v-for="(lang) in languages" :key="lang.value" px-4 @click="setUserLocale(lang.value)">
          <v-layout row>
            <v-checkbox v-model="lang.selected" /> <flag :iso="lang.iso" />
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="success"
          :disabled="!canSave"
          @click="updateUser"
        >
          {{ $t("userProfile.saveChanges") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import userAvatar from "@/components/shared/userAvatar";
import UPDATE_USER from "@/graphql/users/update.gql";
import REMOVE_PROFILE_PICTURE from "@/graphql/users/remove_profile_picture.gql";
import uploader from "@/components/shared/uploader";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "settingsProfile",
  components: {
    uploader,
    userAvatar,
  },
  data() {
    return {
      showUploadDialog: false,
      user: {},
      languages: {
        en: {
          value: 'en',
          iso: 'gb',
          selected: false
        },
        fr: {
          value: 'fr',
          iso: 'fr',
          selected: false
        }
      }
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'locale']),
    canSave() {
      return true;
    },
    noProfilePicture() {
      return !this.currentUser || !this.currentUser.avatar_url;
    }
  },
  methods: {
    ...mapActions(['setInstance']),
    setUserLocale(value) {
      this.user.locale = value;
      this.setSelectedLanguages();
    },
    setUserAvatarBlob(file, blob) {
      this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          id: this.user.id,
          avatar_signed_blob_id: blob.signed_id
        }
      }).then(({ data }) => {
        this.setInstance({
          model: 'user',
          instance: data.update_user
        })
        this.sendSuccess(this.$t('userProfile.profilePicUpdated'))
        //this.setCurrentUser(data.update_user);
      }).catch(error => {
        this.sendError(error);
        console.log(error);
      });
    },
    removeAvatar() {
      if (!this.currentUser) return;
      this.$apollo.mutate({
        mutation: REMOVE_PROFILE_PICTURE,
        variables: { id: this.currentUser.id },
      }).then(({ data }) => {
        this.sendSuccess(this.$t('userProfile.profilePicRemoved'))
        //this.setCurrentUser(data.remove_profile_picture_user);
      });
    },
    updateUser() {
      if (!this.currentUser) return;

      let oldLocale = this.locale;

      this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: this.user
      }).then(({ data }) => {
        this.sendSuccess(this.$t('userProfile.userUpdated'));
        //this.setCurrentUser(data.update_user);
        //console.log(this.user.locale)
        /* If locale has changed, reload the page */
        if (oldLocale != this.user.locale) {
          localStorage.default_locale = this.user.locale;
          this.$router.go();
        }
      });
    },
    updateUserField(nameField, newValue) {
      this.user[nameField] = newValue;
    },
    resetUser() {
      if (!this.currentUser) return;

      const fields = ["id", "first_name", "last_name", "avatar_url", "job"];
      fields.forEach((f) => {
        this.user[f] = this.currentUser[f];
      });
      this.user.locale = this.locale;
      this.setSelectedLanguages();
    },
    setSelectedLanguages() {
      this.languages.en.selected = this.user.locale === 'en';
      this.languages.fr.selected = this.user.locale === 'fr';
    },
    updateAvatarUrl(event) {
      this.updateUserField("avatar_url", event);
      this.updateUser();
      this.showUploadDialog = false;
    },
  },
  created() {
    this.resetUser();
  },
  watch: {
    "currentUser.first_name"() {
      this.resetUser();
    }
  }
};
</script>
