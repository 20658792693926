import { DirectUpload } from "@rails/activestorage"

export class Uploader {
  constructor(file) {
    this.url = `${process.env.VUE_APP_API_URL}/rails/active_storage/direct_uploads`;
    this.upload = new DirectUpload(file, this.url, this)
  }

  create() {
    return new Promise((resolve, reject) => {
      this.upload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          resolve(blob)
        }
      });
    });
  }
}
